<template>
  <b-container fluid="md" class="mt-1" style="max-width: 900px;">
    <div class="poster" ref="posterTop" v-html="posterTop"></div>
    <!-- 如果有至少一行数据，则渲染第一行 -->
    <div v-if="groupedGameList.length">
      <!-- 第一行游戏 -->
      <b-row cols="2" cols-sm="3" cols-md="4" cols-lg="4">
        <b-col v-for="(item, idx) in groupedGameList[0]" :key="idx" class="px-3 my-3" @click="onGameClicked(item)">
          <b-card style="max-width: 20rem; box-shadow: 2px 3px 5px #222;">
            <b-aspect>
              <b-card-img-lazy :src="item.icon" :alt="item.name" rounded></b-card-img-lazy>
            </b-aspect>
          </b-card>
          <div class="mt-3 game-title">{{ item.name }}</div>
        </b-col>
      </b-row>

      <!-- 中间谷歌广告位 -->
      <div class="poster" ref="posterMid" v-html="posterMid"></div>
    </div>

    <!-- 后续行游戏 -->
      <b-row cols="2" cols-sm="3" cols-md="4" cols-lg="4">
        <b-col v-for="(item, idx) in groupedGameList[1]" :key="idx" class="px-3 my-3" @click="onGameClicked(item)">
          <b-card style="max-width: 20rem; box-shadow: 2px 3px 5px #222;">
            <b-aspect>
              <b-card-img-lazy :src="item.icon" :alt="item.name" rounded></b-card-img-lazy>
            </b-aspect>
          </b-card>
          <div class="mt-3 game-title">{{ item.name }}</div>
        </b-col>
      </b-row>
    <infinite-loading :identifier="infiniteId" @infinite="getGameList"></infinite-loading>
  </b-container>
</template>

<script>
import gameApi from '../api/gameApi.js';
import { getPosterList, getChannelFromUrl, initPoster } from '../api/ipapi.js';

export default {
  name: "GameList",
  data() {
    return {
      gameList: [],
      page: 0,
      isLoading: false,
      pageSize: 20,
      infiniteId: +new Date(),
      posterTop: '',
      posterMid: '',
      windowWidth: window.innerWidth, // 用于计算每行显示的个数
    };
  },
  props: {
    gameType: {
      type: Number,
      default: 0,
    },
    gameName: {
      type: String,
      default: '',
    },
    hot: {
      type: Number,
      default: -1,
    },
    recommend: {
      type: Number,
      default: -1,
    },
    excludeGameId: {
      type: Number,
      default: -1,
    },
  },
  computed: {
    // 根据窗口宽度决定每行显示的游戏个数，与 b-row 的 cols 设置对应
    perRow() {
      if (this.windowWidth < 576) {
        return 2;
      } else if (this.windowWidth < 768) {
        return 3;
      } else {
        return 4;
      }
    },
    // 将 gameList 按 perRow 分组，生成二维数组
    groupedGameList() {
      // 指定切割位置（从数组第 n 个元素开始切割）
      // 注意：数组索引从 0 开始，如果你希望从第 n 个元素（下标 n-1）开始切割，可适当调整
      const n = this.perRow;

      // 使用 slice 方法切割数组
      const firstPart = this.gameList.slice(0, n * 3);  // 获取从第 0 个到第 n-1 个元素
      const secondPart = this.gameList.slice(n * 3);    // 获取从第 n 个元素到最后一个元素

      // 合并为一个二维数组
      const groups = [firstPart, secondPart];

      return groups;
    },
  },
  watch: {
    gameType() {
      this.refresh();
    },
  },
  created() {
    getPosterList("bytebulletin.com", "game_list", getChannelFromUrl(window.location.search.substring(1))).then(res => {
      console.log(res.data.data)
      for (let i = 0; i < res.data.data.items.length; i++) {
        // console.log(res.data.data.items[i].position + ";" + res.data.data.items[i].code)
        initPoster(res.data.data.items[i], this)
      }
    }).catch(err => {
      console.log(err)
    })
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    // 初始化谷歌广告（确保 index.html 已引入 adsbygoogle.js）
    // (adsbygoogle = window.adsbygoogle || []).push({});
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    refresh() {
      this.isLoading = false;
      this.page = 0;
      this.gameList = [];
      this.infiniteId += 1;
    },
    getGameList($state) {
      if (!this.isLoading) {
        this.isLoading = true;

        console.log('getGameList: ', this.page, this.pageSize, this.gameType, this.gameName, this.hot, this.recommend);

        gameApi.getGameList(this.page, this.pageSize, this.gameType, this.gameName, this.hot, this.recommend).then((response) => {
          console.log('response', response);
          this.isLoading = false;

          if (response.status === 200 && response.data.code == 0) {
            if (response.data.data.items.length == 0) {
              $state.complete();
              return;
            }

            var shuffledgamelist = this.shuffleArray(response.data.data.items);

            this.gameList = this.gameList.concat(shuffledgamelist);

            //remove game from game list by game id
            if (this.excludeGameId > 0) {
              this.gameList = this.gameList.filter(item => item.id != this.excludeGameId);
            }

            console.log('gameList: ', this.gameList);
            this.page += 20;
            $state.loaded();
          } else {
            console.error('request gameApi.getGameList error', response);
            $state.complete();
          }
        });
      } else {
        $state.loaded();
      }
    },
    onGameClicked(item) {
      console.log('onGameClicked: ', item);
      this.$router.push({ name: 'game', params: { gamename: this.getGameNameWithDash(item.name), gameid: item.id } }).catch(err => { err });
    },
    getGameName(str) {
      if (!str) {
        return
      }
      return str.replace(/([a-z0-9])([A-Z0-9])/g, '$1 $2')
    },
    getGameNameWithDash(str) {
      // convert string with white space into array
      if (!str) {
        return
      }
      return str.replaceAll(' ', '-').toLowerCase();
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../app.scss";

.card-body {
  padding: 0;
}

.card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Optional: scale and crop the image to fill the element */
}

.game-title {
  color: $text-color;
}
</style>